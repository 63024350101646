import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getFormattedAmount } from "./Helpers";
import Stocks from "./Stocks";
import PearCard from "./PearCard";
import NetWorthTrend from "./NetWorthTrend";
import Skeleton from "./interface/Skeleton";
import axios from "axios";
import { IAccountDetails } from "pear-state/account_slice";
import { ConfigurationContext } from "pear-state/configuration_context";

const AccountSection = ({ account }: { account: IAccountDetails }) => {
  function renderRow(title: string, value: { cad: number; usd: number }) {
    return (
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">{title}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
          <div className="grid grid-cols-2">
            <div>{getFormattedAmount(value.cad, "CAD")}</div>
            <div>{getFormattedAmount(value.usd, "USD")}</div>
          </div>
        </dd>
      </div>
    );
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 pt-5 sm:px-6 flex flex-row items-end">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{account.name}</h3>
        <span className="text-xs font-light text-gray-500 px-2">{account.account_type}</span>
      </div>
      <div className="px-4 pt-2 flex flex-row">
        <span className="text-xs text-gray-700 px-2">
          <span>Account balance: </span>
          <span>{getFormattedAmount(account.combined_balance, account.currency)}</span>
        </span>
      </div>

      <div className="px-4 py-2 flex flex-row">
        <span className="text-xs text-gray-700 px-2">
          <span>P/L: </span>
          {account.net_profit < 0 ? "" : `+`}
          {account.net_profit} ( {account.net_profit < 0 ? "" : `+`}
          {account.profit_percentage}% )
        </span>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {renderRow("Total value", account.balance)}
          {renderRow("Net deposits", account.net_deposits)}
          {renderRow("Cash", account.cash_balance)}
          {renderRow("Dividends", account.total_dividends)}
          {renderRow("Commission", account.total_commission)}
        </dl>
      </div>
    </div>
  );
};

const Account = () => {
  const { account_uuid } = useParams();
  const [account, setAccount] = useState<IAccountDetails | null>(null);
  const [balanceDistribution, setBalanceTypeDistribution] = useState([]);
  const { balanceLookbackInDays } = useContext(ConfigurationContext);

  useEffect(() => {
    const data = axios.get(`accounts/${account_uuid}`).then((response) => {
      setAccount(response.data);
    });

    axios
      .get(`reporting/balance-trend?account-uuid=${account_uuid}&lookback=${balanceLookbackInDays}`)
      .then((response) => {
        setBalanceTypeDistribution(response.data);
      });
  }, [account_uuid, balanceLookbackInDays]);

  function renderBalanceTrend() {
    return (
      <PearCard title="Balance history">
        <NetWorthTrend data={balanceDistribution} />
      </PearCard>
    );
  }

  function renderAddNewTransactionButton() {
    return (
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
      >
        <a href="/transactions/new">New Transaction</a>
      </button>
    );
  }

  return account ? (
    <div className="w-full">
      {account.transaction_count === 0 && renderAddNewTransactionButton()}
      <div className="mt-4">
        <AccountSection account={account} />
      </div>
      <div className="mt-4">{renderBalanceTrend()}</div>
      <div className="mt-4">{account.account_type !== "Cash" ? <Stocks account_uuid={account.uuid} /> : null}</div>
    </div>
  ) : (
    <Skeleton />
  );
};

export default Account;
