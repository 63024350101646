import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { usePagination } from "hooks/usePagination";
import classNames from "classnames";

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({ currentPage, totalCount, onPageChange }: PaginationProps) => {
  const paginationRange = usePagination({ currentPage, totalCount, pageSize: 50 });
  let lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="flex w-0 flex-1">
        <a
          onClick={onPrevious}
          className={classNames(
            "inline-flex cursor-pointer items-center border-t-2 border-transparent py-4 px-3 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700",
            currentPage == 1 ? "hidden" : ""
          )}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </a>
      </div>
      <div className="hidden md:flex">
        {paginationRange.map((pageNumber) => {
          return (
            <a
              onClick={() => onPageChange(pageNumber)}
              key={pageNumber}
              className={classNames(
                "inline-flex cursor-pointer items-center border-t-2 px-4 py-4 text-sm font-medium",
                currentPage == pageNumber
                  ? "border-indigo-500 text-indigo-600"
                  : "bordertransparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              )}
            >
              {pageNumber}
            </a>
          );
        })}
      </div>
      <div className="flex w-0 flex-1 justify-end">
        <a
          onClick={onNext}
          className={classNames(
            "inline-flex cursor-pointer items-center border-t-2 border-transparent py-4 px-3 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700",
            currentPage == lastPage ? "hidden" : ""
          )}
        >
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  );
};

export default Pagination;
