import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import classNames from "classnames";
import EmptyState from "components/EmptyState";
import { getFormattedAmount } from "components/Helpers";
import Pagination from "components/interface/Pagination";
import SnackbarContext from "pear-state/snackbar_context";
import { fetchStocks, IStock } from "pear-state/stock_slice";
import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import { ITransactionResponse } from "pear-state/transaction_slice";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface RecentTransactionsTableProps {
  transactions: ITransactionResponse[];
}

interface RecentTransaction extends ITransactionResponse {
  currentPrice: number;
  isTradeGreen: boolean;
  profit: number;
}

export default function RecentTransactionsTable({ transactions }: RecentTransactionsTableProps) {
  const equitiesData = useSelector((state: RootState) => state.stocks.stocks);
  const equitiesStatus = useSelector((state: RootState) => state.stocks.status);

  const [result, setResult] = useState<RecentTransaction[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (equitiesStatus === StoreStatus.IDLE) {
      dispatch(fetchStocks());
    } else if (equitiesStatus === StoreStatus.SUCCEEDED) {
      computeResult();
    }
  }, [equitiesStatus, transactions, equitiesData]);

  if (equitiesStatus === "succeeded" && equitiesData.length === 0) {
    return <EmptyState />;
  }

  function computeResult() {
    const stocks: IStock[] = equitiesData;
    // Create a copy of the transactions array before sorting
    const sortedTransactions = [...transactions].sort((a, b) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });

    const last10Transactions: ITransactionResponse[] = [];
    sortedTransactions.forEach((transaction) => {
      if (last10Transactions.length < 10) {
        if (transaction.transaction_type == "Buy" || transaction.transaction_type == "Sell") {
          last10Transactions.push(transaction);
        }
      }
    });

    const r: RecentTransaction[] = [];
    last10Transactions.forEach((transaction) => {
      const stock = stocks.find((stock) => stock.symbol == transaction.symbol);
      if (stock) {
        const currentValue = stock.current_price * transaction.number_of_units;
        const profit = currentValue - transaction.total_amount;

        // If I sold the transaction and the profit is positive, I lost because I sold too early.
        // If I bought the transaction and the profit is positive, I won because I bought low.

        var isTradeGreen = false;
        if (transaction.transaction_type == "Sell") {
          isTradeGreen = profit <= 0;
        } else {
          isTradeGreen = profit >= 0;
        }

        r.push({ ...transaction, currentPrice: stock.current_price, isTradeGreen, profit });
      }
    });

    setResult(r);
  }

  return (
    <div className="mt-4">
      <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8 h-full">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Details
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Transaction Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Current Price
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    P/L
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {result.map((transaction, transactionIdx) => (
                  <tr key={transaction.uuid} className={transactionIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {transaction.transaction_date as string}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction.details}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {getFormattedAmount(transaction.price_per_unit, transaction.currency)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {getFormattedAmount(transaction.currentPrice, transaction.currency)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={classNames(
                          "text-white  px-2.5 py-0.5 rounded-full text-xs",
                          transaction.isTradeGreen ? "bg-green-600" : "bg-red-600"
                        )}
                      >
                        {getFormattedAmount(transaction.profit, transaction.currency)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
