import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./App.css";
import { Provider } from "react-redux";
import App from "./App";
import store from "./pear-state/store";
import { SnackBarContextProvider } from "pear-state/snackbar_context";
import { ConfigurationProvider } from "pear-state/configuration_context";

import axios from "axios";
import ReactGA from "react-ga4";

Sentry.init({
  dsn: "https://0fae5b8429304b989ead5a8ea438a13c@o4504385481342976.ingest.sentry.io/4504385483505665",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
  enabled: process.env.NODE_ENV == "production",
});

const GA_TRACKING_ID = "G-N31ZRTPXYT"; // OUR_TRACKING_ID
ReactGA.initialize(GA_TRACKING_ID);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// https://pragmaticstudio.com/tutorials/rails-session-cookies-for-api-authentication
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfCookieName = "CSRF-TOKEN";
// axios.defaults.xsrfHeaderName = "X-CSRF-Token";

axios.interceptors.request.use(function (config) {
  const access_token = localStorage.getItem("token");

  if (access_token) {
    config.headers.common["Authorization"] = `Bearer ${access_token}`;
  }

  return config;
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigurationProvider>
        <SnackBarContextProvider>
          <App name="MoneySavvy" />
        </SnackBarContextProvider>
      </ConfigurationProvider>
    </Provider>
  </React.StrictMode>
);
