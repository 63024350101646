import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getFormattedAmount } from "./Helpers";
import Stocks from "./Stocks";
import PearCard from "./PearCard";
import NetWorthTrend from "./NetWorthTrend";
import Skeleton from "./interface/Skeleton";
import axios from "axios";
import { fetchAccounts, IAccountDetails } from "pear-state/account_slice";
import { ConfigurationContext } from "pear-state/configuration_context";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "pear-state/store";

const Stock = () => {
  const { symbol } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const accountsStatus = useSelector((state: RootState) => state.accounts.status);

  useEffect(() => {
    if (accountsStatus === "idle") {
      dispatch(fetchAccounts());
    }
  }, [accounts, dispatch]);

  return <Skeleton />;
};

export default Stock;
