import React, { useEffect } from "react";
import Dividends from "./Dividends";
import RecentTransactionsTable from "./transactions/RecentTransactionsTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "pear-state/store";
import { fetchTransactions } from "pear-state/transaction_slice";
import { fetchAccounts } from "pear-state/account_slice";
import CashBreakdownTable from "./CashBreakdownTable";

const Reports: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const transactions = useSelector((state: RootState) => state.transactions?.transactions || []);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);

  useEffect(() => {
    dispatch(fetchTransactions());
    dispatch(fetchAccounts());
  }, [dispatch]);

  return (
    <div className="w-full">
      <h1 className="text-2xl font-semibold mb-6">Reports</h1>
      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold mb-4">Recent Transactions</h2>
          <RecentTransactionsTable transactions={transactions} />
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Cash Breakdown</h2>
          <CashBreakdownTable accounts={accounts} />
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Dividends</h2>
          <Dividends />
        </section>
      </div>
    </div>
  );
};

export default Reports;
