import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import { fetchAccountTypeDistribution } from "pear-state/user_slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HousingInputForm from "./HousingInputForm";
import classNames from "classnames";
import { getFormattedAmount } from "./Helpers";

export interface HousingInputFormFilters {
  housePrice: number;
  mortgageRate: number;
  ammortization: number;
  additionalDownpayment: number;
  monthlyExpenses: number;
  downpaymentPercentage: number;
}
const defaultRowValues: HousingInputFormFilters = {
  housePrice: 999999,
  mortgageRate: 5.0,
  ammortization: 300,
  additionalDownpayment: 0,
  monthlyExpenses: 2000,
  downpaymentPercentage: 0.3,
};

const Housing = () => {
  const dispatch = useDispatch<AppDispatch>();

  const accountTypeDistribution = useSelector((state: RootState) => state.users.accountTypeDistribution);
  const accountTypeDistributionStatus = useSelector((state: RootState) => state.users.accountTypeDistributionStatus);
  const [inputData, setInputData] = useState(defaultRowValues);

  useEffect(() => {
    if (accountTypeDistributionStatus === StoreStatus.IDLE) {
      dispatch(fetchAccountTypeDistribution());
    }
  }, [dispatch]);

  const columns = ["", "Scenario 1", "Scenario 2", "Scenario 3", "Scenario 4"];
  function renderHeader() {
    return (
      <thead className="bg-gray-50">
        <tr>
          {columns.map((column, idx) => {
            return (
              <th
                key={column}
                scope="col"
                className={classNames(
                  "py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer",
                  idx == 0 ? "pl-6" : "px-3"
                )}
              >
                <div className="flex flex-row items-center">
                  <div>{column}</div>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  function calculateLandTransferTax(housePrice: number) {
    return ((housePrice - 400000) * 0.02 + 150000 * 0.015 + 195000 * 0.01 + 55000 * 0.005) * 2 - 8475;
  }

  interface RowData {
    label: string;
    fieldType: "Numeric" | "Amount" | "Percentage";
    scenarios: number[];
    fontWeight: "normal" | "bold" | "light";
  }
  function buildDefaults(
    label: string,
    fieldType: "Numeric" | "Amount" | "Percentage",
    scenarioLength: number,
    fontWeight: "normal" | "bold" | "light" = "normal"
  ): RowData {
    return {
      label: label,
      fieldType: fieldType,
      scenarios: Array(scenarioLength).fill(0),
      fontWeight: fontWeight,
    };
  }

  function calculateScenarios() {
    const mortgageRate = inputData.mortgageRate / 100;
    const landTransferTax = calculateLandTransferTax(inputData.housePrice);
    const downPaymentPercentages = [0.1, 0.15, 0.2, inputData.downpaymentPercentage];
    const closingCosts = 12000;

    let totalFundsInHand = 0;
    accountTypeDistribution.forEach((account) => {
      if (account.name == "Retirement") {
        totalFundsInHand += 35000;
      } else if (account.name === "TFSA") {
        totalFundsInHand += account.balance;
      } else if (account.name === "Cash") {
        totalFundsInHand += account.balance - 60000;
      } else if (account.name === "Taxable") {
        totalFundsInHand += account.balance;
      }
    });

    const result = {
      downpaymentPercentage: buildDefaults("Downpayment Percentage", "Percentage", downPaymentPercentages.length),
      downPayment: buildDefaults("Downpayment", "Amount", downPaymentPercentages.length),
      mortgageInsurance: buildDefaults("Mortgage Insurance", "Amount", downPaymentPercentages.length),
      salesTaxOnMortgageInsurance: buildDefaults(
        "Sales Tax on Mortgage Insurance",
        "Amount",
        downPaymentPercentages.length
      ),
      totalMortgage: buildDefaults("Total Mortgage", "Amount", downPaymentPercentages.length),
      totalCashRequired: buildDefaults("Total Cash Required", "Amount", downPaymentPercentages.length),
      monthlyMortgagePayment: buildDefaults(
        "Monthly Mortgage Payment",
        "Amount",
        downPaymentPercentages.length,
        "bold"
      ),
      utilites: buildDefaults("Utilites", "Amount", downPaymentPercentages.length),
      propertyTax: buildDefaults("Property Tax", "Amount", downPaymentPercentages.length),
      otherMonthlyExpenses: buildDefaults("Other Monthly Expenses", "Amount", downPaymentPercentages.length),
      totalMonthlyExpenses: buildDefaults("Total Monthly Expenses", "Amount", downPaymentPercentages.length, "bold"),
      leftoverLiquidSavings: buildDefaults("Leftover Liquid Savings", "Amount", downPaymentPercentages.length),
      yearsWithoutJob: buildDefaults("Years Without Job", "Numeric", downPaymentPercentages.length),
    };

    downPaymentPercentages.forEach((downPaymentPercentage, index) => {
      result.downpaymentPercentage.scenarios[index] = downPaymentPercentage * 100;
      result.utilites.scenarios[index] = 500;
      result.downPayment.scenarios[index] = inputData.housePrice * downPaymentPercentage;
      result.mortgageInsurance.scenarios[index] =
        downPaymentPercentage < 0.2 ? 0.04 * (inputData.housePrice - result.downPayment.scenarios[index]) : 0;
      result.salesTaxOnMortgageInsurance.scenarios[index] = result.mortgageInsurance.scenarios[index] * 0.08;
      result.totalMortgage.scenarios[index] =
        inputData.housePrice - result.downPayment.scenarios[index] + result.mortgageInsurance.scenarios[index];
      result.totalCashRequired.scenarios[index] =
        result.downPayment.scenarios[index] +
        result.salesTaxOnMortgageInsurance.scenarios[index] +
        landTransferTax +
        closingCosts -
        inputData.additionalDownpayment;

      // monthly mortgage payment. Uses PMT in excel;
      result.monthlyMortgagePayment.scenarios[index] =
        (result.totalMortgage.scenarios[index] * mortgageRate) /
        12 /
        (1 - (1 + mortgageRate / 12) ** -inputData.ammortization);

      result.propertyTax.scenarios[index] = (inputData.housePrice * 0.007) / 12;
      result.otherMonthlyExpenses.scenarios[index] = inputData.monthlyExpenses;
      result.totalMonthlyExpenses.scenarios[index] =
        result.monthlyMortgagePayment.scenarios[index] +
        result.utilites.scenarios[index] +
        result.propertyTax.scenarios[index] +
        result.otherMonthlyExpenses.scenarios[index];
      result.leftoverLiquidSavings.scenarios[index] = totalFundsInHand - result.totalCashRequired.scenarios[index];
      result.yearsWithoutJob.scenarios[index] =
        result.leftoverLiquidSavings.scenarios[index] / result.totalMonthlyExpenses.scenarios[index] / 12;
    });

    return result;
  }

  const scenariosResult = calculateScenarios();

  function renderRow(fieldName: string, rowData: RowData) {
    const result = [];
    result.push(
      <td key={fieldName} className={classNames("whitespace-nowrap py-4 text-sm text-gray-500", "pl-6 font-medium")}>
        {rowData.label}
      </td>
    );

    rowData.scenarios.forEach((value: number, colIdx: number) => {
      result.push(
        <td
          key={`${value}-${colIdx}`}
          className={classNames("whitespace-nowrap py-4 text-sm text-gray-500 px-3", `font-${rowData.fontWeight}`)}
        >
          {(() => {
            if (rowData.fieldType == "Percentage") {
              return value.toFixed(2) + "%";
            } else if (rowData.fieldType == "Numeric") {
              return value.toFixed(2);
            } else if (rowData.fieldType == "Amount") {
              return getFormattedAmount(value, "CAD");
            }
          })()}
        </td>
      );
    });

    return result;
  }

  return (
    <div className="flex-1">
      <div>Housing</div>
      <div>
        <HousingInputForm defaultRowValues={inputData} onFilterSubmit={(data) => setInputData(data)} />
      </div>

      <div className="mt-4 h-full w-full mx-auto">
        <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8 h-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                {renderHeader()}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Object.entries(scenariosResult).map(([fieldName, scenarios], idx) => (
                    <tr key={fieldName} className={idx % 2 === 0 ? undefined : "bg-gray-50"}>
                      {renderRow(fieldName, scenarios)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Housing;
