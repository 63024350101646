import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface IStock {
  symbol: string;
  number_of_units: number;
  currency: "USD" | "CAD";
  cost_basis: number;
  current_price: number;
  percent_change: number;
  price_date: string;
  total_profit: number;
  total_dividends: number;
  total_gain_with_dividends: number;
  annualized_return: number;
  current_value: number;
  current_value_in_cad: number;
}
interface StockStoreState {
  stocks: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error?: string | undefined;
}

const initialState: StockStoreState = {
  stocks: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: undefined,
};

export const fetchStocks = createAsyncThunk("stocks/fetch", async () => {
  const res = await axios.get("stocks");
  return res.data;
});

export const stockSlice = createSlice({
  name: "stocks",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStocks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStocks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stocks = action.payload;
      })
      .addCase(fetchStocks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default stockSlice.reducer;
