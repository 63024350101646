import { useController } from "react-hook-form";
import { CloudArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";

interface FileUploadProps {
  formName: string;
  displayName: string;
  control: any;
  rules: any;
  handleFileChange: (e: any) => void;
  removeFile: () => void;
}

const FileUpload = ({
  formName,
  displayName,
  control,
  rules,
  handleFileChange,
  removeFile,
}: FileUploadProps) => {
  const {
    field: { value, onChange, ref },
  } = useController({ name: formName, control, rules });


  return (
    <div>
      <div className={"relative mt-1 shadow-sm"}>
        <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex text-sm text-gray-600 justify-center">
              <label
                htmlFor="brokerFile"
                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
              >
                <span>{displayName}</span>
                <input
                  id={formName}
                  name={formName}
                  type="file"
                  ref={ref}
                  onClick={(e) => ((e.target as HTMLInputElement).value = "")} // https://stackoverflow.com/a/48499451
                  className="sr-only"
                  onChange={handleFileChange}
                />
              </label>
            </div>
            <p className="text-xs text-gray-500">CSV, XLSX, PDF up to 10MB</p>
          </div>
        </div>
      </div>
      {value?.name != null && (
        <div className="mt-2 text-gray-600 text-sm flex flex-row items-center">
          <div>{value?.name}</div>
          <TrashIcon
            className="h-4 w-4 ml-1 bottom-0 text-gray-600 hover:text-gray-900 cursor-pointer"
            onClick={removeFile}
          />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
