import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface ITransaction {
  transaction_uuid?: string;
  transactionType: string;
  currency: "CAD" | "USD";
  account: string;
  transactionDate: string;
  amount: number;
  pricePerUnit: number;
  numberOfUnits: number;
  commission: number;
  symbol: string;
}

export interface ITransactionResponse {
  uuid: string;
  total_amount: number;
  currency: "CAD" | "USD";
  transaction_date: string;
  transaction_type: string;
  account_uuid: string;
  details: string;
  symbol: string;
  price_per_unit: number;
  number_of_units: number;
  commission: number;
}

export interface IAccountMapping {
  broker_name: string;
  account_uuid: string;
  brokerage_account_number: string;
}

interface TransactionStoreState {
  transactions: ITransactionResponse[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error?: string;
}

const initialState: TransactionStoreState = {
  transactions: [],
  status: "idle",
  error: undefined,
};

export const createNewTransaction = createAsyncThunk("transactions/createTransaction", async (transaction: any) => {
  const { data } = await axios.post(`transactions`, transaction);
  return data;
});

export const updateExistingTransaction = createAsyncThunk(
  `transactions/updateTransaction`,
  async (updateData: { transaction_uuid: string; transactionData: any }) => {
    const { transaction_uuid, transactionData } = updateData;
    const { data } = await axios.put(`transactions/${transaction_uuid}`, transactionData);
    return data;
  }
);

export const fetchTransactions = createAsyncThunk("transactions/fetchTransactions", async () => {
  const { data } = await axios.get("transactions");
  return data;
});

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createNewTransaction.fulfilled, (state, action) => {
        state.transactions.push(action.payload);
      })
      .addCase(updateExistingTransaction.fulfilled, (state, action) => {
        const index = state.transactions.findIndex((t) => t.uuid === action.payload.uuid);
        if (index !== -1) {
          state.transactions[index] = action.payload;
        }
      });
  },
});

export default transactionSlice.reducer;
