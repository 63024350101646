import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IAccountProperties, createNewAccount, updateExistingAccount, IAccountDetails } from "../pear-state/account_slice";
import { useForm } from "react-hook-form";
import TextField from "./interface/TextField";
import SelectField from "./interface/SelectField";
import SnackbarContext from "../pear-state/snackbar_context";
import Skeleton from "./interface/Skeleton";
import axios from "axios";
import { AppDispatch } from "pear-state/store";

interface AccountFormProps {
  account: IAccountDetails;
  action: "Create" | "Update";
}

const AccountForm = ({ account, action }: AccountFormProps) => {
  let navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const snackbarCtx = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: account?.name || "",
      type: account?.account_type || "",
      currency: account?.currency || "",
    },
  });

  const createAccount = (data: IAccountProperties) => {
    dispatch(createNewAccount(data))
      .unwrap()
      .then((response) => {
        snackbarCtx.displayMessage(response.message, response.status === "success" ? "bg-pear-opal" : "bg-red-500");
        navigate(`/accounts/${response.account.uuid}`);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const updateAccount = (data: IAccountProperties) => {
    dispatch(
      updateExistingAccount({
        account_uuid: account.uuid,
        accountData: data,
      })
    )
      .unwrap()
      .then((response) => {
        snackbarCtx.displayMessage(response.message, response.status === "success" ? "bg-pear-opal" : "bg-red-500");
        navigate(`/accounts/${response.account.uuid}`);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const onSubmit = (submissionData: IAccountProperties) => {
    action === "Create" ? createAccount(submissionData) : updateAccount(submissionData);
  };

  function renderNameField() {
    return (
      <TextField
        formName="name"
        displayName="Account Name"
        control={control}
        rules={{ required: true }}
        widthClass="w-full"
      />
    );
  }

  function renderAccountType() {
    return (
      <SelectField
        formName="type"
        displayName="Account Type"
        control={control}
        rules={{ required: true }}
        items={[
          { uuid: "Taxable", name: "Taxable" },
          { uuid: "Cash", name: "Cash" },
          { uuid: "Retirement", name: "Retirement" },
          { uuid: "TFSA", name: "TFSA" },
        ]}
      />
    );
  }

  function renderCurrency() {
    return (
      <SelectField
        formName="currency"
        displayName="Display currency"
        control={control}
        rules={{ required: true }}
        items={[
          { uuid: "CAD", name: "CAD" },
          { uuid: "USD", name: "USD" },
        ]}
      />
    );
  }

  function renderForm() {
    return (
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {renderNameField()}
        {renderAccountType()}
        {renderCurrency()}
        <button
          type="submit"
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </form>
    );
  }

  return (
    <div className="w-full md:w-1/2 mt-4">
      <div className="md:rounded-lg shadow bg-white p-4">{renderForm()}</div>
    </div>
  );
};

interface AccountFormPageProps {
  action: "Create" | "Update";
}
const AccountFormPage = ({ action }: AccountFormPageProps) => {
  const { account_uuid } = useParams();
  const [account, setAccount] = useState<IAccountDetails | null>(null);

  useEffect(() => {
    if (account_uuid != null) {
      axios.get(`accounts/${account_uuid}`).then((response) => {
        setAccount(response.data);
      });
    }
  }, [account_uuid]);

  if (account_uuid != null && account === null) {
    return <Skeleton />;
  }

  return <AccountForm account={account as IAccountDetails} action={action} />;
};

export default AccountFormPage;
