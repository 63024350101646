import { Listbox, Transition } from "@headlessui/react";
import { useController } from "react-hook-form";

import classNames from "classnames";
import React, { Fragment } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface SelectFieldProps {
  items: { uuid: string; name: string; subtext?: string }[];
  displayName: string;
  formName: string;
  control: any;
  rules?: any;
  widthClass?: string;
}

const SelectField = ({ items, displayName, formName, control, rules, widthClass }: SelectFieldProps) => {
  const {
    field: { value, onChange, ref },
  } = useController({ name: formName, control, rules });

  const selectedItemName = items.find((item) => item.uuid === value)?.name;

  return (
    <div>
      <Listbox
        value={value}
        onChange={onChange}
        /*
      // @ts-ignore */
        ref={ref}
        className="relative"
      >
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button
                className={classNames(
                  "relative cursor-default border border-gray-100 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none sm:text-sm",
                  widthClass || "w-full"
                )}
              >
                <span className="inline-flex w-full truncate">
                  <span className="">{selectedItemName}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Label className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500">
                {displayName}
              </Listbox.Label>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={classNames(
                    "absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm",
                    "min-w-full"
                  )}
                >
                  {items.map((item) => (
                    <Listbox.Option
                      key={item.uuid}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={item.uuid}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(selected ? "font-semibold" : "font-normal", "text-sm block truncate")}
                          >
                            {item.name}
                          </span>
                          {item.subtext && (
                            <div className={classNames("text-xs", active ? "text-slate-100" : "text-slate-400")}>
                              {item.subtext}
                            </div>
                          )}

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectField;
