import SelectField from "../../interface/SelectField";
import FileUpload from "../../interface/FileUpload";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import React from "react";

const steps = {
  Questrade: [
    "Login to Questrade on your computer",
    "Go to Account activity page. It's listed under 'Reports' tab in the menu",
    "Select the date range you want to export. If this is your first time, we recommend setting the start date as the date you opened your account",
    "Click 'Export to Excel' button",
  ],
  Wealthsimple: [
    "Login to Wealthsimple Trade on your computer",
    "Go to 'Transactions' page. It's listed under 'Reports' tab in the menu",
  ],
  IBKR: ["Login to IBKR on your computer", "Test test test"],
  Wealthica: [
    "Login to Wealthica on your computer",
    "Go to Power-ups and click on Google Sheets export",
    "Click on Create spreadsheet > Export transactions > All time",
    "This will create a new Google Sheet with all your transactions.",
    "Go to Google sheets and Click on File > Download > Comma-separated values (.csv, current sheet)",
  ],
};

interface FileUploadStepProps {
  brokerage: IBrokerages;
  brokerFile: File | string;
  setBulkUploadFormState: (state: any) => void;
}

export enum IBrokerages {
  None = "",
  Questrade = "Questrade",
  Wealthsimple = "Wealthsimple",
  IBKR = "IBKR",
  Wealthica = "Wealthica",
}

type FormSubmissionTypes = {
  brokerage: IBrokerages;
  brokerFile: File | string;
};

const FileUploadStep = ({ brokerage, brokerFile, setBulkUploadFormState }: FileUploadStepProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm<FormSubmissionTypes>({
    defaultValues: {
      brokerage: brokerage,
      brokerFile: brokerFile,
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setValue("brokerFile", file || "");
  };

  const removeFile = () => {
    setValue("brokerFile", "");
  };

  const brokerSelection: IBrokerages = watch("brokerage") as IBrokerages;

  const onSubmit: SubmitHandler<FormSubmissionTypes> = (submissionData) => {
    const formData = new FormData();
    formData.append("file", submissionData.brokerFile);
    formData.append("brokerage", submissionData.brokerage);

    axios
      .post("transactions/extract-accounts-from-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setBulkUploadFormState((prevState: any) => {
          return {
            ...prevState,
            brokerage: submissionData.brokerage,
            brokerFile: submissionData.brokerFile,
            step: 1,
            accountMappings: response.data.account_mappings,
          };
        });
      });
  };

  return (
    <div className="bg-white p-4 border w-2/3">
      <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">1. Select your brokerage</h3>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <SelectField
                  formName="brokerage"
                  displayName="Brokerage"
                  control={control}
                  rules={{ required: true }}
                  items={[
                    { uuid: "Questrade", name: "Questrade" },
                    // { uuid: "Wealthsimple", name: "Wealthsimple" },
                    // { uuid: "IBKR", name: "IBKR" },
                    { uuid: "Wealthica", name: "Wealthica" },
                  ]}
                  widthClass="w-1/2"
                />
              </div>
            </div>
            <p className="mt-1 max-w-2xl text-xs text-gray-500">
              If you would like a new broker added, please contact us at{" "}
              <span className="text-pear-purple">hello@moneysavvy.ca</span>
            </p>
          </div>

          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">2. Get your transaction history file</h3>
              <ul className="mt-1 max-w-2xl text-sm list-decimal list-inside leading-7 text-gray-500">
                {brokerSelection && steps[brokerSelection].map((step) => <li key={step}>{step}</li>)}
              </ul>
            </div>
          </div>

          <div className="space-y-6 divide-y divide-gray-200 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">3. Upload your transaction history file</h3>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                <div className="mt-1 sm:col-span-3 sm:mt-0">
                  <FileUpload
                    formName="brokerFile"
                    displayName="Upload your file"
                    control={control}
                    rules={{ required: true }}
                    handleFileChange={handleFileChange}
                    removeFile={removeFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-start">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FileUploadStep;
