import { useController } from "react-hook-form";
import classNames from "classnames";

interface TextFieldProps {
  formName: string;
  displayName: string;
  control: any;
  rules?: any;
  type?: string;
  widthClass?: string;
  disabled?: boolean;
}

const TextField = ({
  formName,
  displayName,
  control,
  rules,
  type,
  widthClass,
  disabled,
}: TextFieldProps) => {
  const {
    field: { value, onChange, ref },
  } = useController({ name: formName, control, rules });

  return (
    <div>
      <div className={"relative mt-1 shadow-sm"}>
        <label
          htmlFor={formName}
          className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500"
        >
          {displayName}
        </label>
        <input
          type={type || "text"}
          name={formName}
          id={formName}
          ref={ref}
          className={classNames(
            "block px-3 sm:text-sm border-gray-100",
            widthClass,
            disabled ? "bg-gray-100 text-gray-500" : "bg-white"
          )}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default TextField;
