import axios from "axios";

const Impersonate = () => {
  function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const email = (e.target as any).email.value;

    axios.post("/impersonate", { email }).then((response) => {
      console.log(response);
    });
  }

  return (
    <div>
      <h1>Impersonate</h1>
      <form onSubmit={submitHandler} method="POST">
        <input id="email" name="email" type="email" placeholder="Email" />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Impersonate;
