import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import classNames from "classnames";
import EmptyState from "components/EmptyState";
import { getFormattedAmount } from "components/Helpers";
import Pagination from "components/interface/Pagination";
import { IAccountDetails } from "pear-state/account_slice";
import SnackbarContext from "pear-state/snackbar_context";
import { fetchStocks, IStock } from "pear-state/stock_slice";
import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import { ITransactionResponse } from "pear-state/transaction_slice";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface CashBreakdownTableProps {
  accounts: IAccountDetails[];
}

export default function CashBreakdownTable({ accounts }: CashBreakdownTableProps) {
  const sortedAccounts = accounts.slice().sort((a, b) => b.cash_balance.combined_cad - a.cash_balance.combined_cad);

  return (
    <div className="mt-4">
      <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8 h-full">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Account Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    CAD Balance
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    USD Balance
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Total Balance
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedAccounts.map((account, accountIdx) => (
                  <tr key={account.uuid} className={accountIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {getFormattedAmount(account.cash_balance.cad, "CAD")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {getFormattedAmount(account.cash_balance.usd, "USD")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {getFormattedAmount(account.cash_balance.combined_cad || 0, "CAD")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
