import { useController } from "react-hook-form";
import classNames from "classnames";

interface DatePickerProps {
  displayName: string;
  formName: string;
  defaultValue: string;
  rules: any;
  control: any;
}

const DatePicker = ({
  displayName,
  formName,
  defaultValue,
  rules,
  control,
}: DatePickerProps) => {
  const {
    field: { value, onChange },
  } = useController({ name: formName, control, rules, defaultValue });

  return (
    <div>
      <div className="relative mt-1 rounded-md shadow-sm">
        <label
          htmlFor={formName}
          className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500"
        >
          {displayName}
        </label>
        <input
          id={formName}
          name={formName}
          className={"border-gray-100 block w-36 px-3 text-sm"}
          type="date"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default DatePicker;
