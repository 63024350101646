import { useController } from "react-hook-form";
import classNames from "classnames";

interface AmountFieldProps {
  amountFormName: string;
  currencyFormName?: string;
  displayName: string;
  control: any;
  rules?: any;
  withCurrency?: boolean;
}

const AmountField = ({
  amountFormName,
  currencyFormName,
  displayName,
  control,
  rules,
  withCurrency,
}: AmountFieldProps) => {
  const {
    field: { value, onChange },
  } = useController({ name: amountFormName, control, rules });

  const { field: currencyField } = useController({
    name: currencyFormName || `${amountFormName}Currency`,
    control,
    defaultValue: "CAD",
  });

  function renderCurrencyInput() {
    return (
      <div className="absolute inset-y-0 right-0 flex items-center">
        <label htmlFor="currency" className="sr-only">
          Currency
        </label>
        <select
          id={currencyFormName}
          name={currencyFormName}
          value={currencyField.value}
          onChange={currencyField.onChange}
          className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 sm:text-sm"
        >
          <option>CAD</option>
          <option>USD</option>
        </select>
      </div>
    );
  }
  return (
    <div>
      <div className="relative mt-1 shadow-sm">
        <label
          htmlFor={amountFormName}
          className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500"
        >
          {displayName}
        </label>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="text"
          name={amountFormName}
          id={amountFormName}
          className={classNames(
            "block w-full pl-7 sm:text-sm border-gray-100",
            withCurrency === true ? "pr-12" : ""
          )}
          placeholder="0.00"
          value={value}
          onChange={onChange}
        />
        {withCurrency === true ? renderCurrencyInput() : null}
      </div>
    </div>
  );
};

export default AmountField;
