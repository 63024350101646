import { Fragment, useState, useContext } from "react";
import {
  CurrencyDollarIcon,
  ChartBarIcon,
  DocumentTextIcon,
  HomeIcon,
  XMarkIcon,
  Bars3CenterLeftIcon,
  ArrowLeftOnRectangleIcon,
  EyeIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { GlobeAltIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUserState, logoutUser, updatePortfolio } from "pear-state/user_slice";
import { AppDispatch } from "pear-state/store";
import { ConfigurationContext } from "../pear-state/configuration_context";

const Navigation = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { toggleAmounts } = useContext(ConfigurationContext);

  const navItems = [
    {
      icon: HomeIcon,
      route: "/dashboard",
      label: "Dashboard",
    },
    {
      icon: DocumentTextIcon,
      route: "/accounts",
      label: "Accounts",
    },
    {
      icon: GlobeAltIcon,
      route: "/equities",
      label: "Equities",
    },
    {
      icon: CurrencyDollarIcon,
      route: "/transactions",
      label: "Transactions",
    },
    {
      icon: ChartBarIcon,
      route: "/reports",
      label: "Reports",
    },
  ];

  function currentPageRoute() {
    return window.location.pathname.split("/")[1];
  }

  function logout() {
    localStorage.removeItem("token");
    dispatch(fetchUserState());
  }

  function sendUpdatePortfolio() {
    dispatch(updatePortfolio());
  }

  function renderNavbar() {
    return (
      <div className="lg:hidden flex h-16 flex-shrink-0 border-b border-gray-200 bg-pear-light-brown ">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-400 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:px-8">
          <div className="flex flex-1">
            <div className="w-full h-full flex items-center text-slate-800"></div>
          </div>
        </div>
      </div>
    );
  }

  function renderSidebarContents() {
    return (
      <>
        <div className="flex flex-shrink-0 items-center px-4">
          <img src="/moneysavvy.svg" className="h-12 w-auto" alt="Logo" />
        </div>
        <nav className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
          <div className="space-y-1 px-2">
            {navItems.map((item) => (
              <a
                key={item.label}
                href={item.route}
                className={classNames(
                  currentPageRoute() === item.route.split("/")[1]
                    ? "bg-pear-light-brown-highlight text-gray-800"
                    : "text-gray-600 hover:text-black",
                  "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                )}
              >
                <item.icon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
                {item.label}
              </a>
            ))}
          </div>
        </nav>

        <div
          className="flex flex-shrink-0 p-4 text-gray-600 hover:text-black hover:cursor-pointer"
          onClick={() => sendUpdatePortfolio()}
        >
          <BoltIcon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
          Update Portfolio
        </div>
        <div
          className="flex flex-shrink-0 p-4 text-gray-600 hover:text-black hover:cursor-pointer"
          onClick={() => toggleAmounts()}
        >
          <EyeIcon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
          Visibility
        </div>
        <div
          className="flex flex-shrink-0 p-4 text-gray-600 hover:text-black hover:cursor-pointer"
          onClick={() => logout()}
        >
          <ArrowLeftOnRectangleIcon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
          Logout
        </div>
      </>
    );
  }

  function renderSidebar() {
    return (
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-pear-light-brown pt-6">
          {renderSidebarContents()}
        </div>
      </div>
    );
  }

  function renderTransition() {
    return (
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-pear-light-brown pt-6 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {renderSidebarContents()}
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <>
      {renderTransition()}
      {renderSidebar()}
      <div className="flex flex-1 flex-col lg:pl-64 h-screen">
        {renderNavbar()}
        <div className="md:px-8 py-8 flex flex-grow">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Navigation;
