// @ts-ignore
import { ReactComponent as Logo } from "./skeleton.svg";

const Skeleton = () => {
  return (
    <div className="flex flex-col h-screen w-full animate-pulse justify-center items-center">
      <Logo className="h-10 w-10" />
    </div>
  );
};

export default Skeleton;