import { useMemo } from "react";

// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

interface PaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
}

export const usePagination = ({
  totalCount,
  pageSize,
  currentPage,
}: PaginationProps) => {
  const range = (start: number, end: number) => {
    let length = end - start + 1;
    /*
      Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    return range(1, totalPageCount);
  }, [totalCount, pageSize, currentPage]);

  return paginationRange;
};
