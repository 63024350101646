import Chart from "react-apexcharts";
import { useContext, useEffect, useState } from "react";
import { ConfigurationContext } from "../pear-state/configuration_context";
import axios from "axios";
import { init } from "@sentry/react";

interface NetWorthTrendProps {
  data: {
    time: string;
    balance: number;
    net_deposit: number;
    VOO: number;
    "XEQT.TO": number;
    "VFV.TO": number;
    "VDY.TO": number;
  }[];
}

const timeOptions = [
  { name: "1M", value: 30 },
  { name: "3M", value: 90 },
  { name: "6M", value: 180 },
  { name: "1Y", value: 363 },
];

const compSeriesOptions = ["VOO", "XEQT.TO", "VFV.TO", "VDY.TO"];

const NetWorthTrend = ({ data }: NetWorthTrendProps) => {
  const xaxis = data?.map((distribution) => distribution.time);
  const yaxis = data?.map((distribution) => distribution.balance);

  const { showAmounts } = useContext(ConfigurationContext);
  const { balanceLookbackInDays, setBalanceLookbackInDays } = useContext(ConfigurationContext);
  const [selectedCompSeries, setSelectedCompSeries] = useState<Set<string>>(new Set());

  // If there is only one data point, add a second data point that's 5 days ago
  if (data?.length === 1) {
    const date = Date.parse(xaxis[0]);
    xaxis.push(new Date(date - 5 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]);
    yaxis.push(yaxis[0]);
  }

  const state = {
    options: {
      chart: {
        id: `area-${showAmounts}`,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth" as "smooth" | "straight" | "stepline",
        width: 2,
      },
      grid: {
        show: false,
      },
      colors: ["#84A59D", "#F4A261", "#2A9D8F", "#E9C46A", "#264653"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      xaxis: {
        type: "datetime" as "datetime" | "category" | "numeric",
        categories: xaxis,
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            if (showAmounts) {
              return `$${Number(val).toLocaleString()}`;
            } else {
              return `$*********`;
            }
          },
        },
        // min: function (min) {
        //   return min - 10;
        // },
        // max: function (max) {
        //   return max + 10;
        // },
      },
    },
    series: getSeries(),
  };

  function getSeries() {
    // var series: { name: string; data: number[] }[] = [];
    const netGrowth = data?.map((distribution) => distribution["balance"] - distribution["net_deposit"]);
    const initialNetGrowth = netGrowth[0];

    var series = [
      {
        name: "Net worth",
        data: yaxis,
      },
    ];
    // if (selectedCompSeries.size === 0) {
    // } else {
    //   series = [
    //     {
    //       name: "Net gain",
    //       data: netGrowth,
    //     },
    //   ];
    // }

    selectedCompSeries.forEach((symbol) => {
      var symbolPrices: number[] = [];
      const normalizedPrices: number[] = [];

      switch (symbol) {
        case "VOO":
          symbolPrices = data?.map((distribution) => distribution.VOO);
          break;
        case "XEQT.TO":
          symbolPrices = data?.map((distribution) => distribution["XEQT.TO"]);
          break;
        case "VFV.TO":
          symbolPrices = data?.map((distribution) => distribution["VFV.TO"]);
          break;
        case "VDY.TO":
          symbolPrices = data?.map((distribution) => distribution["VDY.TO"]);
          break;
      }

      var initialPrice = symbolPrices[0];
      var i = 0;
      while (initialPrice === 0) {
        i++;
        initialPrice = symbolPrices[i];
      }
      const ratio = yaxis[0] / initialPrice;
      console.log(ratio);

      symbolPrices.forEach((symbolPrice) => {
        const normalizedPrice = Math.round(symbolPrice * ratio * 100) / 100;

        normalizedPrices.push(normalizedPrice);
      });

      series.push({
        name: symbol,
        data: normalizedPrices,
      });
    });

    if (selectedCompSeries.size !== 0) {
      // Normalize the data by subtracting any new net deposits
      const netDeposits = data?.map((distribution) => distribution["net_deposit"]);
      const initialNetDeposit = netDeposits[0];

      series = series.map((serie) => {
        if (serie.name !== "Net worth") {
          return serie;
        }
        return {
          name: serie.name,
          data: serie.data.map((value, i) => {
            const newDeposit = netDeposits[i] - initialNetDeposit;
            return value - newDeposit;
          }),
        };
      });
    }

    return series;
  }

  function renderRadioButtons() {
    return (
      <fieldset className="mt-2 float-left">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
          {timeOptions.map((option) => (
            <div key={option.name} className="flex items-center">
              <input
                id={option.name}
                name="time-range"
                type="radio"
                defaultChecked={option.value === balanceLookbackInDays}
                className="h-3 w-3 border-gray-300 text-pear-purple focus:ring-0"
                onClick={() => setBalanceLookbackInDays(option.value)}
              />
              <label htmlFor={option.name} className="ml-3 block text-sm leading-6 text-gray-600">
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }

  function renderComparisons() {
    return (
      <fieldset className="mt-2 float-right">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
          {compSeriesOptions.map((option) => (
            <div key={option} className="flex items-center">
              <input
                id={option}
                name="time-range"
                type="checkbox"
                defaultChecked={false}
                className="h-3 w-3 border-gray-300 text-pear-purple focus:ring-0"
                onChange={(e) => {
                  const newSet = new Set(selectedCompSeries);
                  e.target.checked ? newSet.add(option) : newSet.delete(option);
                  setSelectedCompSeries(newSet);
                }}
              />
              <label htmlFor={option} className="ml-3 block text-sm leading-6 text-gray-600">
                {option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }

  return (
    <div className="flex flex-col">
      <div>
        {renderRadioButtons()}
        {renderComparisons()}
      </div>
      <div>
        {data !== null ? (
          <Chart options={state.options} series={state.series} type="area" width="100%" height="300" />
        ) : null}
      </div>
    </div>
  );
};

export default NetWorthTrend;
