import TransactionForm from "../TransactionForm";
import Skeleton from "components/interface/Skeleton";
import { ITransactionResponse } from "pear-state/transaction_slice";
import { IAccountProperties } from "pear-state/account_slice";

interface ReviewTransactionsStepProps {
  transactions: ITransactionResponse[];
  accounts: IAccountProperties[];
}

const ReviewTransactionsStep = ({ transactions, accounts }: ReviewTransactionsStepProps) => {
  if (transactions.length === 0) {
    return <Skeleton />;
  }

  return (
    <div className="w-full">
      <div>Review Transactions</div>
      <TransactionForm
        accounts={accounts}
        transactions={transactions}
        action={"BulkCreate"}
      />
    </div>
  );
};

export default ReviewTransactionsStep;
