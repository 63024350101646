import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "pear-state/store";
import { createUser, fetchUserState, loginUser } from "pear-state/user_slice";
import ReactGA from "react-ga4";

export enum AuthPageVariant {
  LOGIN = "login",
  SIGNUP = "signup",
}

interface AuthPageProps {
  variant: AuthPageVariant;
  isLoggedIn?: boolean;
}

const AuthPage = (props: AuthPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [queryParams, setQueryParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");

  const email = queryParams.get("email") || "";

  useEffect(() => {
    if (props.isLoggedIn) {
      return navigate("/");
    }
  }, [props.isLoggedIn]);

  // TODO: Handle errors
  function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const email = (e.target as any).email.value;
    const password = (e.target as any).password.value;

    if (props.variant === AuthPageVariant.SIGNUP) {
      ReactGA.event("sign_up");
      dispatch(createUser({ email, password }))
        .then(() => dispatch(fetchUserState()))
        .catch((e) => console.log(e));
    } else {
      ReactGA.event("login");
      dispatch(loginUser({ email, password })).then((response: any) => {
        if (response.payload.error) {
          setErrorMessage(response.payload.error.message);
        } else {
          localStorage.setItem("token", response.payload.data);
          dispatch(fetchUserState());
        }
      });
    }

    return;
  }

  function inversePrompt() {
    const url = props.variant === AuthPageVariant.LOGIN ? "/signup" : "/login";
    const text1 = props.variant === AuthPageVariant.LOGIN ? "Don't have an account?" : "Already have an account?";
    const text2 = props.variant === AuthPageVariant.LOGIN ? "Sign up" : "Log in";

    return (
      <div className="flex items-center justify-center">
        <div className="text-sm">
          {text1}{" "}
          <a href={url} className="font-medium text-indigo-600 hover:text-indigo-500">
            {text2}
          </a>
        </div>
      </div>
    );
  }

  function showForgotPassword() {
    return (
      <div className="flex items-center justify-left">
        <div className="text-sm">
          <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot password?
          </a>
        </div>
      </div>
    );
  }

  function showErrorMessage() {
    if (errorMessage === "") {
      return;
    }

    return <div className="text-red-600 text-sm">Error: {errorMessage}</div>;
  }

  return (
    <div className="flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="h-16 md:h-24 w-auto mx-auto" src="/moneysavvy.svg" alt="Logo" />
      </div>

      <div className="mt-4 mx-4 sm:mx-auto md:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitHandler} method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  defaultValue={email}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            {props.variant === AuthPageVariant.LOGIN && showForgotPassword()}
            {showErrorMessage()}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {props.variant === AuthPageVariant.LOGIN ? "Sign in" : "Sign up"}
              </button>
            </div>
            {/* {inversePrompt()} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
