import AmountField from "components/interface/AmountField";
import DatePicker from "components/interface/DatePicker";
import TextField from "components/interface/TextField";
import SelectField from "components/interface/SelectField";
import StockAutocomplete from "components/interface/StockAutocomplete";
import { IAccountProperties } from "pear-state/account_slice";

interface ComponentProps {
  formName: string;
  control: any;
  displayName: string;
  rules?: { required: boolean };
  widthClass?: string;
}

// TODO: Duplicated from FieldMapping to fix cyclic dependency
enum TransactionType {
  Buy = "Buy",
  Sell = "Sell",
  Dividend = "Dividend",
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
}

export const TransactionTypeDropdown = (props: ComponentProps) => {
  const items = Object.keys(TransactionType).map((key) => {
    return { uuid: key, name: key };
  });

  return (
    <SelectField
      items={items}
      formName={props.formName}
      control={props.control}
      rules={props.rules ?? { required: true }}
      displayName={props.displayName}
    />
  );
};

interface AccountDropdownProps extends ComponentProps {
  accounts: IAccountProperties[];
}

export const AccountDropdown = (props: AccountDropdownProps) => {
  const items = props.accounts.map((account) => {
    return {
      uuid: account.uuid as string,
      name: account.name,
      subtext: account.account_type,
    };
  });

  return (
    <SelectField
      items={items}
      formName={props.formName}
      control={props.control}
      rules={props.rules ?? { required: true }}
      widthClass="w-40"
      displayName={props.displayName}
    />
  );
};

interface PriceInputProps extends ComponentProps {
  rowIndex: number;
  withCurrency?: boolean;
}
export const PriceInput = (props: PriceInputProps) => {
  return (
    <AmountField
      amountFormName={props.formName}
      currencyFormName={`data[${props.rowIndex}].currency`}
      control={props.control}
      rules={{ required: true }}
      withCurrency={true}
      displayName={props.displayName}
    />
  );
};

export const UnitCountInput = (props: ComponentProps) => {
  return (
    <TextField
      formName={props.formName}
      type="number"
      control={props.control}
      rules={{ required: true }}
      widthClass="w-full"
      displayName={props.displayName}
    />
  );
};

export const SymbolAutocomplete = (props: ComponentProps) => {
  return (
    <StockAutocomplete
      formName={props.formName}
      control={props.control}
      rules={props.rules ?? { required: true }}
      displayName={props.displayName}
    />
  );
};

export const TransactionDate = (props: ComponentProps) => {
  return (
    <DatePicker
      defaultValue={
        // props.transaction?.transaction_date ||
        new Date().toLocaleDateString("en-CA").split("T")[0]
      }
      formName={props.formName}
      rules={props.rules ?? { required: true }}
      control={props.control}
      displayName={props.displayName}
    />
  );
};

export const CommissionInput = (props: ComponentProps) => {
  return (
    <AmountField
      amountFormName={props.formName}
      control={props.control}
      rules={{ required: false }}
      displayName={props.displayName}
    />
  );
};
