import { useController } from "react-hook-form";
import { useState, useEffect } from "react";
import classNames from "classnames";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import axios from "axios";

interface StockAutocompleteProps {
  displayName: string;
  formName: string;
  control: any;
  rules: any;
}

interface IStockItem {
  symbol: string;
  name: string;
  type: string;
  exchange: string;
  currency?: string;
}

const StockAutocomplete = ({ displayName, formName, control, rules }: StockAutocompleteProps) => {
  const { field } = useController({ name: formName, control, rules });

  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState<IStockItem[]>([]);

  useEffect(() => {
    if (query.length > 1) {
      axios.get(`search-symbols?prefix=${query}`).then((response) => {
        setFilteredItems(response.data);
      });
    } else {
      setFilteredItems([]);
    }
  }, [query]);

  return (
    <Combobox
      as="div"
      value={field.value}
      onChange={field.onChange}
      ref={field.ref}
    >
      <div className="relative mt-1">
        <Combobox.Label className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500">
          {displayName}
        </Combobox.Label>
        <Combobox.Input
          className="w-32 border bg-white py-2 pl-3 pr-10 shadow-sm sm:text-sm border-gray-100"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(v: any) => v}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item.symbol}
                value={item.symbol}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {item.symbol}{" "}
                      <span
                        className={classNames(
                          "text-xs",
                          active ? "text-gray-100" : "text-gray-600"
                        )}
                      >
                        {item.name}
                      </span>
                    </span>
                    <div
                      className={classNames(
                        "text-xs",
                        active ? "text-slate-100" : "text-slate-600"
                      )}
                    >
                      {item.exchange} - {item.currency}
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default StockAutocomplete;
