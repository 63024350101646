import TextField from "components/interface/TextField";
import SelectField from "components/interface/SelectField";
import { useForm, useFieldArray, FieldArrayWithId } from "react-hook-form";
import { useEffect } from "react";
import axios from "axios";
import { IAccountProperties } from "pear-state/account_slice";
import { IAccountMapping } from "pear-state/transaction_slice";

interface ReviewAccountsStepProps {
  accounts: IAccountProperties[];
  accountMappings: IAccountMapping[];
  goBack: () => void;
  setBulkUploadFormState: (state: any) => void;
}

const ReviewAccountsStep = ({ accounts, accountMappings, goBack, setBulkUploadFormState }: ReviewAccountsStepProps) => {
  const defaultRowValues = {
    brokerage_account_number: "",
    account_uuid: "",
    broker_name: "",
  };
  const items = accounts.map((account) => {
    return {
      uuid: account.uuid as string,
      name: account.name,
      subtext: account.account_type,
    };
  });

  const {
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      data: [defaultRowValues],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "data",
  });

  useEffect(() => {
    reset({ data: accountMappings });
  }, [reset]);

  const onSubmit = (submissionData: any) => {
    axios.post("/accounts/update-account-mappings", { data: submissionData.data }).then((response) => {
      setBulkUploadFormState((prevState: any) => {
        return {
          ...prevState,
          step: 2,
          accountMappings: response.data.account_mappings,
        };
      });
    });
  };

  function renderRow(data: FieldArrayWithId, index: number) {
    return (
      <div key={data.id} className="flex flex-row pt-4 gap-2 w-full">
        <div className="w-full">
          <div className="hidden">
            <TextField
              formName={`data[${index}].broker_name`}
              control={control}
              displayName={"Brokerage name"}
              disabled={true}
              widthClass="w-full"
            />
          </div>
          <TextField
            formName={`data[${index}].brokerage_account_number`}
            control={control}
            displayName={"Brokerage Account Number"}
            disabled={true}
            widthClass="w-full"
          />
        </div>
        <div className="w-full">
          <SelectField
            items={items}
            formName={`data[${index}].account_uuid`}
            control={control}
            rules={{ required: true }}
            widthClass="w-full"
            displayName={"Your account"}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white p-4 border w-full lg:w-1/2">
      <h3>Review Accounts</h3>
      <p className="text-xs text-gray-500">{"Please match your brokerage account to your account in MoneySavvy"}</p>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {fields.map((data, index) => {
          return renderRow(data, index);
        })}
        <div className="flex flex-row gap-2 justify-start mt-2">
          <button
            onClick={() => goBack()}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Back
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReviewAccountsStep;
