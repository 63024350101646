import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccounts } from "../../pear-state/account_slice";
import { useParams } from "react-router-dom";
import TransactionForm from "./TransactionForm";
import Skeleton from "components/interface/Skeleton";
import axios from "axios";
import { AppDispatch, RootState } from "pear-state/store";

const TransactionFormContainer = () => {
  const { transaction_uuid } = useParams();

  const [transaction, setTransaction] = useState(null);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const dispatch = useDispatch<AppDispatch>();
  const action = transaction === null ? "Create" : "Update";

  useEffect(() => {
    if (accounts.length === 0) {
      dispatch(fetchAccounts());
    }

    if (transaction_uuid != null) {
      axios
        .get(`transactions/${transaction_uuid}`)
        .then((response) => {
          setTransaction(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [transaction_uuid]);

  if (accounts.length === 0 || (transaction_uuid != null && transaction === null)) {
    return <Skeleton />;
  }

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="p-4 bg-white border">
        <h2 className="font-semibold px-2">{`${action} Transaction`}</h2>
        <TransactionForm accounts={accounts} transactions={transaction === null ? [] : [transaction]} action={action} />
      </div>
    </div>
  );
};

export default TransactionFormContainer;
