import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import { useEffect, useState } from "react";
import { createDemoUser, fetchUserState } from "pear-state/user_slice";
import Skeleton from "./interface/Skeleton";
import { useNavigate } from "react-router-dom";

const CreateDemoPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [creationStarted, setCreationStarted] = useState(false);
  const isLoggedIn = useSelector((state: RootState) => state.users.loggedIn);

  useEffect(() => {
    if (creationStarted) {
      return;
    }

    if (isLoggedIn) {
      return navigate("/");
    }

    setCreationStarted(true);
    console.log("Create demo user");

    dispatch(createDemoUser()).then((response: any) => {
      if (response.payload.error) {
        console.log(response.payload.error.message);
        return navigate("/");
      } else {
        dispatch(fetchUserState());
      }
    });
  }, [isLoggedIn]);

  return <Skeleton />;
};

export default CreateDemoPage;
