import {
  TransactionDate,
  AccountDropdown,
  TransactionTypeDropdown,
  SymbolAutocomplete,
  PriceInput,
  UnitCountInput,
  CommissionInput,
} from "./FormComponents";

interface TransactionField {
  displayName: string;
  formName: string;
  component: React.FC<any>;
}
interface TransactionFieldProps {
  Buy: TransactionField[];
  Sell: TransactionField[];
  Dividend: TransactionField[];
  Deposit: TransactionField[];
  Withdrawal: TransactionField[];
}

export enum TransactionType {
  Buy = "Buy",
  Sell = "Sell",
  Dividend = "Dividend",
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
}

export const transactionFields: TransactionFieldProps = {
  Buy: [
    {
      displayName: "Date",
      formName: "transactionDate",
      component: TransactionDate,
    },
    {
      displayName: "Account",
      formName: "account",
      component: AccountDropdown,
    },
    {
      displayName: "Type",
      formName: "transactionType",
      component: TransactionTypeDropdown,
    },
    {
      displayName: "Symbol",
      formName: "symbol",
      component: SymbolAutocomplete,
    },
    {
      displayName: "Price per unit",
      formName: "pricePerUnit",
      component: PriceInput,
    },
    {
      displayName: "Unit count",
      formName: "numberOfUnits",
      component: UnitCountInput,
    },
    {
      displayName: "Commission",
      formName: "commission",
      component: CommissionInput,
    },
  ],
  Sell: [
    {
      displayName: "Date",
      formName: "transactionDate",
      component: TransactionDate,
    },
    {
      displayName: "Account",
      formName: "account",
      component: AccountDropdown,
    },
    {
      displayName: "Type",
      formName: "transactionType",
      component: TransactionTypeDropdown,
    },
    {
      displayName: "Symbol",
      formName: "symbol",
      component: SymbolAutocomplete,
    },
    {
      displayName: "Price per unit",
      formName: "pricePerUnit",
      component: PriceInput,
    },
    {
      displayName: "Unit count",
      formName: "numberOfUnits",
      component: UnitCountInput,
    },
    {
      displayName: "Commission",
      formName: "commission",
      component: CommissionInput,
    },
  ],
  Dividend: [
    {
      displayName: "Date",
      formName: "transactionDate",
      component: TransactionDate,
    },
    {
      displayName: "Account",
      formName: "account",
      component: AccountDropdown,
    },
    {
      displayName: "Type",
      formName: "transactionType",
      component: TransactionTypeDropdown,
    },
    {
      displayName: "Symbol",
      formName: "symbol",
      component: SymbolAutocomplete,
    },
    {
      displayName: "Total amount",
      formName: "amount",
      component: PriceInput,
    },
  ],
  Deposit: [
    {
      displayName: "Date",
      formName: "transactionDate",
      component: TransactionDate,
    },
    {
      displayName: "Account",
      formName: "account",
      component: AccountDropdown,
    },
    {
      displayName: "Type",
      formName: "transactionType",
      component: TransactionTypeDropdown,
    },
    {
      displayName: "Amount",
      formName: "amount",
      component: PriceInput,
    },
  ],
  Withdrawal: [
    {
      displayName: "Date",
      formName: "transactionDate",
      component: TransactionDate,
    },
    {
      displayName: "Account",
      formName: "account",
      component: AccountDropdown,
    },
    {
      displayName: "Type",
      formName: "transactionType",
      component: TransactionTypeDropdown,
    },
    {
      displayName: "Amount",
      formName: "amount",
      component: PriceInput,
    },
  ],
};
