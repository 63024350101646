import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "pear-state/store";
import { createUser, fetchUserState, loginUser, resetPassword } from "pear-state/user_slice";
import ReactGA from "react-ga4";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [queryParams, setQueryParams] = useSearchParams();
  const email = queryParams.get("email") || "";
  const token = queryParams.get("token") || "";
  const [message, setMessage] = useState<string | null>(null);
  const [errorMessages, setErrors] = useState<string[]>([]);

  function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const email = (e.target as any).email.value;
    const password = (e.target as any).password.value;

    dispatch(resetPassword({ email, password, token }))
      .then((res: any) => {
        if ((res.payload.errors || []).length > 0) {
          setErrors(res.payload.errors);
          setMessage(null);
        } else {
          setErrors([]);
          setMessage("Your password has been reset.");
        }
      })
      .catch((err) => {
        setErrors(["Something went wrong. Please email hello@moneysavvy.ca for support."]);
        setMessage(null);
      });
  }

  function showOutcome() {
    function showErrors() {
      return (
        <div className="text-red-600" role="alert">
          <span className="block sm:inline text-sm"> {errorMessages.join(" ")}</span>
        </div>
      );
    }

    function showMessage() {
      return (
        <div className="text-slate-600 text-sm" role="alert">
          <span className="block sm:inline">{message}</span>{" "}
          <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
            Click here to log in
          </a>
        </div>
      );
    }

    return (
      <div>
        {errorMessages.length > 0 && showErrors()}
        {message && showMessage()}
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="h-16 md:h-24 w-auto mx-auto" src="/moneysavvy.svg" alt="Logo" />
      </div>

      <div className="mt-4 mx-4 sm:mx-auto md:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
        <div>
            <h3 className="text-center text-xl font-semibold text-gray-900">Reset password</h3>
            <p className="mt-2 text-center text-xs text-gray-600">
              Please enter your new password.
            </p>
          </div>
          <form className="mt-6 space-y-6" onSubmit={submitHandler} method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  disabled
                  defaultValue={email}
                  required
                  className="block w-full text-slate-500 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Reset Password
              </button>
            </div>
            {showOutcome()}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
