import { useForm, useFieldArray } from "react-hook-form";
import {
  AccountDropdown,
  SymbolAutocomplete,
  TransactionDate,
  TransactionTypeDropdown,
} from "./transaction_form/FormComponents";
import { IAccountDetails } from "pear-state/account_slice";

interface TransactionFilterProps {
  account_uuid: string;
  transaction_type: string;
  symbol: string;
  transaction_date: string;
}

interface TransactionFilterFormProps {
  accounts: IAccountDetails[];
  onFilterSubmit: (data: any) => void;
}

const TransactionFilterForm = ({ accounts, onFilterSubmit }: TransactionFilterFormProps) => {
  const defaultRowValues: TransactionFilterProps = {
    account_uuid: "",
    transaction_type: "",
    symbol: "",
    transaction_date: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultRowValues,
  });

  function onSubmit(data: any) {
    onFilterSubmit(data);
  }

  return (
    <div className="w-full flex flex-col gap-4 my-4 p-4 bg-white border-gray-100 border-2">
      <div className="flex flex-row gap-4">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="flex flex-row gap-4">
            <AccountDropdown
              accounts={accounts}
              formName="account_uuid"
              displayName="Account"
              control={control}
              rules={{ required: false }}
            />
            <TransactionDate
              formName="transaction_date"
              displayName="Transaction Date"
              control={control}
              rules={{ required: false }}
            />
            <TransactionTypeDropdown
              formName="transaction_type"
              displayName="Type"
              control={control}
              rules={{ required: false }}
              widthClass="w-40"
            />
            <SymbolAutocomplete formName="symbol" displayName="Symbol" control={control} rules={{ required: false }} />
            <button>Filter</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransactionFilterForm;
