import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/20/solid";

const NoTransactionsState = () => {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <div className="text-center m-auto">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No transactions found</h3>
        <p className="mt-1 text-sm text-gray-500">Get started by adding a new transaction.</p>
        <div className="mt-6 flex sm:flex-auto gap-2">
          <button
            type="button"
            onClick={() => navigate("/transactions/new")}
            className="inline-flex items-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Transaction
          </button>
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pear-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none sm:w-auto"
          >
            <a href="/transactions/upload">Upload files</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoTransactionsState;
