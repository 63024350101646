import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const SymbolDetails = () => {
  const { symbol } = useParams();
  const [details, setDetails] = useState({});

  useEffect(() => {
    axios.get(`symbols/${symbol}`).then((response) => setDetails(response.data));
  }, [symbol]);

  return (
    <div>
      <h1>Instrument</h1>
      <pre className="text-xs">{JSON.stringify(details, null, 2)}</pre>
    </div>
  );
};

export default SymbolDetails;
