import classNames from "classnames";

export const getFormattedAmount = (amountInput: string | number | null, currency: "CAD" | "USD", styled?: boolean) => {
  const amount = amountInput == null ? 0 : amountInput;
  const amountFloat = typeof amount === "string" ? parseFloat(amount) : amount;

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "code",
  });
  var currencyStr = formatter.format(amountFloat);

  if (styled) {
    const backgroundColor = amountFloat < 0 ? "bg-red-600" : "bg-green-600";
    return (
      <span className={classNames("text-white  px-2.5 py-0.5 rounded-full text-xs", backgroundColor)}>
        {currencyStr}
      </span>
    );
  }

  return currencyStr;
};

export const compareValues = (a: string | number, b: string | number, ascending: boolean) => {
  if (typeof a === "number" && typeof b === "number") {
    return ascending ? b - a : a - b;
  } else {
    return ascending ? String(b).localeCompare(String(a)) : String(a).localeCompare(String(b));
  }
};
