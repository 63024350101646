import React, { createContext, useContext, useState } from "react";

// Create a context for the visibility of amounts
export const ConfigurationContext = createContext({
  showAmounts: false,
  toggleAmounts: () => {},

  balanceLookbackInDays: 30,
  setBalanceLookbackInDays: (days: number) => {},
});

export const ConfigurationProvider = (props: { children: React.ReactNode }) => {
  const showAmountsStorage = JSON.parse(localStorage.getItem("showAmounts") || "false");
  const [showAmounts, setShowAmounts] = useState(showAmountsStorage);

  const [balanceLookbackInDays, setBalanceLookbackInDays] = useState(30);

  const toggleAmounts = () => {
    localStorage.setItem("showAmounts", JSON.stringify(!showAmounts));
    setShowAmounts(!showAmounts);
  };

  return (
    <ConfigurationContext.Provider
      value={{ showAmounts, toggleAmounts, balanceLookbackInDays, setBalanceLookbackInDays }}
    >
      {props.children}
    </ConfigurationContext.Provider>
  );
};
