import { useMemo } from "react";
import Chart from "react-apexcharts";

// data needs to be in the form of [{ name: abc,  balance: def}]
interface PieChartProps {
  data: { name: string; balance: number }[];
}

const PieChart = ({ data }: PieChartProps) => {
  const labels = data?.map((distribution) => distribution.name);
  const series = data?.map((distribution) => distribution.balance);

  function shuffle(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  const colors = useMemo(() => {
    return shuffle(["#C2CFB2", "#88B7B5", "#D57A66", "#F8E7CB", "#5270D2", "#234E4C"]);
  }, []);

  const state = {
    options: {
      chart: {
        id: "pie",
      },
      labels: labels,
      legend: {
        show: true,
        position: "bottom" as 'top' | 'right' | 'bottom' | 'left',
      },
      colors: colors,
      dataLabels: {
        enabled: false,
        formatter: function (val: number, opts: { seriesIndex: number }) {
          const seriesIndex = opts.seriesIndex;
          const valName = data[seriesIndex].name;
          return `${valName} (${val.toFixed(2)}%)`;
        },
        style: {
          fontSize: "10px",
          fontFamily: "PT Serif",
          colors: ["#333"],
        },
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return `$${Number(val).toLocaleString()}`;
          }
        }
      }
    },
    series: series,
  };

  function renderChart() {
    return (
      <>
        <Chart options={state.options} series={state.series} type="pie" className="w-full" />
      </>
    );
  }

  function renderTable() {
    return (
      <div className="w-1/2 h-full my-auto overflow-hidden shadow md:rounded-lg">
        <table className="divide-y divide-gray-300 w-full">
          <thead className="bg-gray-200">
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Name
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Balance
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.map((obj, objIdx) => {
              return (
                <tr key={obj.name} className={objIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{obj.name}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{`$${Number(
                    obj.balance
                  ).toLocaleString()}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="flex flex-row">
      {renderChart()}
      {/* {chartAlignment === "left" ? renderChart() : renderTable()} */}
      {/* {chartAlignment === "left" ? renderTable() : renderChart()} */}
    </div>
  );
};

export default PieChart;
