import React, { useContext, useState } from "react";
import { Transition } from "@headlessui/react";
import SnackbarContext from "../../pear-state/snackbar_context";
import classNames from "classnames";

const Snackbar = () => {
  const snackbarCtx = useContext(SnackbarContext);

  return (
    <Transition
      show={snackbarCtx.isDisplayed}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames(
          "flex text-white py-1 px-2 rounded-md text-sm",
          snackbarCtx.backgroundColor
        )}
      >
        <div className="snackbar__label">{snackbarCtx.message}</div>
        <div className="pl-2" onClick={snackbarCtx.onClose}>
          &times;
        </div>
      </div>
    </Transition>
  );
};

export default Snackbar;
