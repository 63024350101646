import { useEffect, useMemo } from "react";
import { useState } from "react";
import EmptyState from "./EmptyState";
import Skeleton from "./interface/Skeleton";
import Chart from "react-apexcharts";
import axios from "axios";
import PearCard from "./PearCard";

const groupingOptions = ["None", "Symbol", "Account"];

const Dividends = () => {
  const [dividends, setDividends] = useState({});
  const [dividendsStatus, setDividendsStatus] = useState("idle");
  const [groupingOption, setGroupingOption] = useState("None");

  useEffect(() => {
    if (dividendsStatus !== "idle") {
      return;
    }

    if (Object.keys(dividends).length === 0) {
      setDividendsStatus("loading");
    }

    axios.get("dividends").then((response) => {
      setDividends(response.data);
      setDividendsStatus("succeeded");
    });
  }, [dividendsStatus]);

  if (dividendsStatus === "loading") {
    return <Skeleton />;
  }

  if (Object.keys(dividends).length === 0) {
    return <EmptyState />;
  }

  const xaxis: string[] = [];
  const yaxis: any[] = []; // This will be of shape [{name: "Dividends", data: [1, 2, 3, 4, 5]}]
  const yaxisTotals: number[] = [];
  const yaxisGroupedBySymbol: { [symbol: string]: number[] } = {};
  const yaxisGroupedByAccount: { [account: string]: number[] } = {};

  const numberOfQuarters = Object.keys(dividends).length;

  Object.entries(dividends).forEach(([quarter, report]: [string, any], index: number) => {
    xaxis.push(quarter);
    yaxisTotals.push(report["total_dividend_amount"]);

    Object.entries(report["grouped_by_symbol"]).forEach(([symbol, dividendAmount]: [string, unknown]) => {
      if (yaxisGroupedBySymbol[symbol] === undefined) {
        yaxisGroupedBySymbol[symbol] = Array(numberOfQuarters).fill(0);
      }

      yaxisGroupedBySymbol[symbol][index] = dividendAmount as number;
    });

    Object.entries(report["grouped_by_account"]).forEach(([symbol, dividendAmount]: [string, unknown]) => {
      if (yaxisGroupedByAccount[symbol] === undefined) {
        yaxisGroupedByAccount[symbol] = Array(numberOfQuarters).fill(0);
      }

      yaxisGroupedByAccount[symbol][index] = dividendAmount as number;
    });
  });

  if (groupingOption === "None") {
    yaxis.push({ name: "Dividends", data: yaxisTotals });
  } else if (groupingOption === "Symbol") {
    Object.entries(yaxisGroupedBySymbol).forEach(([symbol, dividendAmounts]: [string, number[]]) => {
      yaxis.push({
        name: symbol,
        data: dividendAmounts,
      });
    });
  } else if (groupingOption === "Account") {
    Object.entries(yaxisGroupedByAccount).forEach(([account, dividendAmounts]: [string, number[]]) => {
      yaxis.push({
        name: account,
        data: dividendAmounts,
      });
    });
  }

  const state = {
    options: {
      chart: {
        id: "bar-dividend",
        stacked: true,
      },

      grid: {
        show: true,
      },
      colors: ["#84A59D", "#F4A261", "#2A9D8F", "#E9C46A", "#264653"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "category" as "datetime" | "category" | "numeric",
        categories: xaxis,
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return `$${Number(val).toLocaleString()}`;
          },
        },
        // min: function (min) {
        //   return min - 10;
        // },
        // max: function (max) {
        //   return max + 10;
        // },
      },
    },
    series: yaxis,
  };

  function renderRadioButtons() {
    return (
      <fieldset className="mt-2 float-left">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
          {groupingOptions.map((option) => (
            <div key={option} className="flex items-center">
              <input
                id={option}
                name="time-range"
                type="radio"
                defaultChecked={option === groupingOption}
                className="h-3 w-3 border-gray-300 text-pear-purple focus:ring-0"
                onClick={() => setGroupingOption(option)}
              />
              <label htmlFor={option} className="ml-3 block text-sm leading-6 text-gray-600">
                {option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }
  return (
    <div className="w-full">
      <h1>Dividends</h1>

      <div className="mt-4 h-full">
        <PearCard title="Balance history">
          <div className="flex flex-col">
            <div>{renderRadioButtons()}</div>

            <Chart options={state.options} series={state.series} type="bar" width="100%" height="300" />
          </div>
        </PearCard>
      </div>
    </div>
  );
};

export default Dividends;
