import { useContext } from "react";
import { ConfigurationContext } from "../../pear-state/configuration_context";

const MaskableText = (props: { value: string | React.ReactNode }) => {
  const { showAmounts, toggleAmounts } = useContext(ConfigurationContext);

  return (
    <div onClick={() => toggleAmounts()} className="hover:cursor-pointer">
      {showAmounts ? props.value : "**********"}
    </div>
  );
};

export default MaskableText;
