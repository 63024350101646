import { IStock } from "pear-state/stock_slice";
import Chart from "react-apexcharts";

const Heatmap = ({ data }: { data: IStock[] }) => {
  function getFillColor(value: number) {
    if (value < -3) {
      return "#f63538";
    } else if (value < -1) {
      return "#bf4045";
    } else if (value < 0) {
      return "#8b444e";
    } else if (value == 0) {
      return "#414554";
    } else if (value > 3) {
      return "#30CC5A";
    } else if (value > 1) {
      return "#32844e";
    } else if (value > 0) {
      return "#3a614f";
    }
  }

  const seriesData = data.map((stock: IStock) => {
    const sign = stock.percent_change > 0 ? "+" : "";
    return {
      x: `${stock.symbol} (${sign}${stock.percent_change}%)`,
      y: stock.current_value_in_cad,
      fillColor: getFillColor(stock.percent_change),
    };
  });

  const series = [
    {
      data: seriesData,
    },
  ];

  const options = {
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
      offsetY: -4,
    },
  };

  return (
    <div className="">
      <Chart type="treemap" options={options} series={series} width="100%" height="auto" />
    </div>
  );
};

export default Heatmap;
