import { useState, useContext } from "react";
import { getFormattedAmount } from "./Helpers";
import MaskableText from "./interface/MaskableText";

interface CardWithChangeProps {
  value: number;
  change: number;
  displayCurrency: "CAD" | "USD";
}

const CardWithChange = (props: CardWithChangeProps) => {
  const { value, change, displayCurrency } = props;
  const [showPercentage, setShowPercentage] = useState(false);

  const changePercentage = (change / (value - change)) * 100;
  const roundedChangePercentage = Math.round(changePercentage * 100) / 100;
  const changeColor = changePercentage > 0 ? "text-green-600" : "text-red-600";
  const changeSign = changePercentage > 0 ? "+" : "-";
  const changeText = showPercentage
    ? `${changeSign}${Math.abs(roundedChangePercentage)}%`
    : `${changeSign}$${new Intl.NumberFormat("en-US").format(Math.abs(change))}`;

  return (
    <div className="flex flex-row gap-1 items-end">
      <div className="bg-white text-gray-600 text-sm">
        <MaskableText value={getFormattedAmount(value, displayCurrency)} />
      </div>
      <div className={`text-xs cursor-pointer ${changeColor}`} onClick={() => setShowPercentage(!showPercentage)}>
        {changeText}
      </div>
    </div>
  );
};

export default CardWithChange;
