import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface AccountStoreState {
  accounts: IAccountDetails[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error?: string;
}

export interface IAccountProperties {
  name: string;
  type?: string;
  currency: "CAD" | "USD";
  account_type?: string;
  uuid?: string;
  balance?: CurrencyBreakdown;
}

interface CurrencyBreakdown {
  cad: number;
  usd: number;
  combined_cad: number;
  combined_usd: number;
}

export interface IAccountDetails {
  uuid: string;
  name: string;
  currency: "CAD" | "USD";
  account_type: string;
  combined_balance: number;
  net_profit: number;
  profit_percentage: number;
  balance: CurrencyBreakdown;
  net_deposits: CurrencyBreakdown;
  cash_balance: CurrencyBreakdown;
  total_dividends: CurrencyBreakdown;
  total_commission: CurrencyBreakdown;
  transaction_count: number;
}

const initialState: AccountStoreState = {
  accounts: [],
  status: "idle",
  error: undefined,
};

export const fetchAccounts = createAsyncThunk("accounts/fetch", async () => {
  const res = await axios.get(`accounts`);

  // const res = await axios.create({ baseURL: "https://dev.supreet-singh.com:6500/api/" }).get(`accounts`);
  return res.data;
});

export const createNewAccount = createAsyncThunk("accounts/createAccount", async (account: IAccountProperties) => {
  const res = await axios.post(`accounts`, account);
  return res.data;
});

export const updateExistingAccount = createAsyncThunk(
  `accounts/updateAccount`,
  async (data: { account_uuid: string; accountData: IAccountProperties }) => {
    const { account_uuid, accountData } = data;
    const res = await axios.put(`accounts/${account_uuid}`, accountData);
    return res.data;
  }
);

export const deleteAccount = createAsyncThunk(`accounts/deleteAccount`, async (account_uuid: string) => {
  const res = await axios.delete(`accounts/${account_uuid}`);
  return res.data;
});

export const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    updateAccountBalance: (state) => {},
  },
  // extraReducers are to process actions defined outside of our slice
  extraReducers(builder) {
    builder
      .addCase(fetchAccounts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accounts = action.payload;
      })
      .addCase(fetchAccounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createNewAccount.fulfilled, (state, action) => {
        // dont push to state since we navigate to the accounts page which fetches again
        state.accounts.push(action.payload);
      });
  },
});

export const { updateAccountBalance } = accountSlice.actions;

export default accountSlice.reducer;
