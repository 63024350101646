import { useEffect, useMemo } from "react";
import { useState } from "react";
import { compareValues, getFormattedAmount } from "./Helpers";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccounts } from "../pear-state/account_slice";
import EmptyState from "./EmptyState";
import Skeleton from "./interface/Skeleton";
import axios from "axios";
import { AppDispatch, RootState } from "pear-state/store";
import { IStock } from "pear-state/stock_slice";
import classNames from "classnames";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

interface StocksProps {
  account_uuid?: string | null;
}

interface ColumnFields {
  fieldName: Exclude<keyof IStock, "currency">;
  label: string;
  type: "text" | "number" | "amount" | "percent";
}

const columns: ColumnFields[] = [
  {
    fieldName: "symbol",
    label: "Symbol",
    type: "text",
  },
  {
    fieldName: "number_of_units",
    label: "Quantity",
    type: "number",
  },
  {
    fieldName: "cost_basis",
    label: "Average price",
    type: "amount",
  },
  {
    fieldName: "current_price",
    label: "Current price",
    type: "amount",
  },
  {
    fieldName: "current_value",
    label: "Current value",
    type: "amount",
  },
  {
    fieldName: "total_profit",
    label: "Profit",
    type: "amount",
  },
  {
    fieldName: "total_dividends",
    label: "Dividends",
    type: "amount",
  },
  {
    fieldName: "total_gain_with_dividends",
    label: "Overall gain",
    type: "amount",
  },
  {
    fieldName: "annualized_return",
    label: "Annualized return",
    type: "percent",
  },
];

const Stocks = ({ account_uuid = null }: StocksProps) => {
  const [stocks, setStocks] = useState<IStock[]>([]);
  const [stocksStatus, setStocksStatus] = useState("idle");
  const [sorting, setSorting] = useState<{ columnName: Exclude<keyof IStock, "currency">; ascending: boolean }>({
    columnName: "symbol",
    ascending: false,
  });
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setStocksStatus("loading");
    let url = `stocks`;

    if (account_uuid) {
      url = `${url}?account-uuid=${account_uuid}`;
    }

    axios.get(url).then((response) => {
      setStocks(response.data);
      setStocksStatus("succeeded");
    });
  }, [account_uuid]);

  const sortedStocks = useMemo(() => {
    return stocks.slice().sort((a, b) => {
      return compareValues(a[sorting.columnName], b[sorting.columnName], sorting.ascending);
    });
  }, [stocks, sorting]);

  if (stocksStatus === "loading") {
    return <Skeleton />;
  }

  function renderArrow(column: ColumnFields) {
    if (column.fieldName === sorting.columnName) {
      if (sorting.ascending) {
        return <ArrowDownIcon className="text-sm h-4" />;
      } else {
        return <ArrowUpIcon className="text-sm h-4" />;
      }
    } else {
      return null;
    }
  }

  function renderHeader() {
    return (
      <thead className="bg-gray-50">
        <tr>
          {columns.map((column, idx) => {
            return (
              <th
                key={column.fieldName}
                scope="col"
                className={classNames(
                  "py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer",
                  idx == 0 ? "pl-6" : "px-3"
                )}
                onClick={() => setSorting({ columnName: column.fieldName, ascending: !sorting.ascending })}
              >
                <div className="flex flex-row items-center">
                  <div>{column.label}</div>
                  <div>{renderArrow(column)}</div>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  function renderRow(stock: IStock) {
    return (
      <>
        {columns.map((column, idx) => {
          return (
            <td
              key={column.fieldName}
              className={classNames(
                "whitespace-nowrap py-4 text-sm text-gray-500",
                idx == 0 ? "pl-6 font-medium" : "px-3"
              )}
            >
              {column.type === "amount"
                ? getFormattedAmount(
                    stock[column.fieldName],
                    stock.currency,
                    column.fieldName === "total_gain_with_dividends"
                  )
                : `${stock[column.fieldName]}${column.type === "percent" ? "%" : ""}`}
            </td>
          );
        })}
      </>
    );
  }

  return (
    <div className="w-full">
      <h1>Equities</h1>
      <div className="mt-4 h-full">
        <div className="-my-2 overflow-x-auto md:-mx-6 lg:-mx-8 h-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                {renderHeader()}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedStocks &&
                    sortedStocks.map((stock, stockIdx: number) => (
                      <tr key={stock.symbol} className={stockIdx % 2 === 0 ? undefined : "bg-gray-50"}>
                        {renderRow(stock)}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stocks;
