import { configureStore } from '@reduxjs/toolkit';
import accountsReducer from './account_slice';
import stocksReducer from './stock_slice';
import usersReducer from './user_slice';
import transactionsReducer from './transaction_slice';

const store = configureStore({
  reducer: {
    accounts: accountsReducer,
    users: usersReducer,
    stocks: stocksReducer,
    transactions: transactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export enum StoreStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
export default store;