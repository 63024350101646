import { useForm } from "react-hook-form";
import AmountField from "./interface/AmountField";
import { HousingInputFormFilters } from "./Housing";
import TextField from "./interface/TextField";

interface HousingInputFormProps {
  defaultRowValues: HousingInputFormFilters;
  onFilterSubmit: (data: any) => void;
}

const HousingInputForm = ({ defaultRowValues, onFilterSubmit }: HousingInputFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultRowValues,
  });

  function onSubmit(data: any) {
    Object.keys(data).forEach((key) => (data[key] = Number(data[key])));
    onFilterSubmit(data);
  }

  return (
    <div className="w-full flex flex-col gap-4 my-4 p-4 bg-white border-gray-100 border-2">
      <div className="flex flex-row gap-4">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="flex flex-row gap-4">
            <AmountField
              amountFormName="housePrice"
              displayName="House Price"
              control={control}
              rules={{ required: false }}
            />
            <TextField
              formName="mortgageRate"
              displayName="Mortgage rate"
              control={control}
              rules={{ required: false }}
            />
            <AmountField
              amountFormName="additionalDownpayment"
              displayName="Additional Downpayment"
              control={control}
              rules={{ required: false }}
            />
            <AmountField
              amountFormName="monthlyExpenses"
              displayName="Monthly Expenses"
              control={control}
              rules={{ required: false }}
            />
            <TextField
              formName="downpaymentPercentage"
              displayName="Custom scenario"
              control={control}
              rules={{ required: false }}
            />
          </div>
          <button className="mt-4 mx-2">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default HousingInputForm;
