import React, { useState } from "react";

let timer: ReturnType<typeof setTimeout>;

export const SnackBarContextProvider = (props: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<string>("pear-opal");
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  const displayHandler = (displayMessage: string, backgroundColor: string) => {
    setMessage(displayMessage);
    setBackgroundColor(backgroundColor);
    setIsDisplayed(true);

    timer = setTimeout(() => {
      closeHandler();
    }, 2000); // close snackbar after 3 seconds
  };

  const closeHandler = () => {
    clearTimeout(timer);
    setIsDisplayed(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        message,
        backgroundColor,
        isDisplayed,
        displayMessage: displayHandler,
        onClose: closeHandler,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};

interface SnackbarContextType {
  message: string;
  backgroundColor: string;
  isDisplayed: boolean;
  displayMessage: (message: string, backgroundColor: string) => void;
  onClose: () => void;
}

const SnackbarContext = React.createContext<SnackbarContextType>({
  message: "",
  isDisplayed: false,
  displayMessage: (message: string, backgroundColor: string) => {},
  backgroundColor: "pear-opal",
  onClose: () => {},
});

export default SnackbarContext;
